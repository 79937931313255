import React, { useState } from 'react';
import { setUserSession } from '../../Utils/Common';
import axios from 'axios';
import { encrypt } from '../helpers/decrypt'

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [tab1active, setTab1active] = useState(true);
    const [tab3active, setTab3active] = useState(false);
    const [myoryggistalan, setOryggistalan] = useState('');

    const [rafinn, setRafinn] = useState(false)
    const [errorinraf, setErrorinraf] = useState(false)

    const username = useFormInput('');
    const password = useFormInput('');
    const farsimanumer = useFormInput('');
    const [error, setError] = useState(null);

    const handleLoginRaf = () => {
        if (farsimanumer.value.length === 7) {
            setErrorinraf(false);
            var val = Math.floor(1000 + Math.random() * 9000);
            setOryggistalan(val);
            setRafinn(true);
            setError(null);
            setLoading(true);

            var data = { 'val': val }
            const headers = { 'Content-Type': 'application/json' }

            axios.post(global.config.api.url.live + '/audkenni/s/'+farsimanumer.value,data, { headers: headers }, { crossdomain: true }).then(response => {
                if (response.data.data.tokenId !== null) {
                    var tokenId = response.data.data.tokenId;
                    console.log(tokenId);
                    var data = { 'phonenum': encrypt(farsimanumer.value)}
                    const headers = {'Content-Type': 'application/json'}
                    axios.post(global.config.api.url.live + '/sessionsrs', data, { headers: headers }, { crossdomain: true }).then(response => {
                        setLoading(false);
                        if (response.data.data.access_token) {
                            setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu, response.data.data.tac, response.data.data.tacv, response.data.data.research);
                            props.history.push('/');
                        } else {
                            setRafinn(false);
                            setLoading(false);
                            setError("Innskráning tókst ekki!");
                        }
                    }).catch(error => {
                        setRafinn(false);
                        setLoading(false);
                        setError("Innskráning tókst ekki!");
                    });
                } else {
                    setRafinn(false);
                    setLoading(false);
                    setError("Innskráning tókst ekki!");
                }
            }).catch(error => {
                setRafinn(false);
                setLoading(false);
                setError("Innskráning tókst ekki!");
            });
        } else {
            setError("Símanúmer ekki rétt!");
            console.log("ekki rétt símanúmer");
        }
    }

    const handleLogin = () => {
        setError(null);
        setLoading(true);
        var data = {
            'username': encrypt(username.value),
            'password': password.value
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post(global.config.api.url.live + '/sessions', data, { headers: headers }, { crossdomain: true }).then(response => {
            setLoading(false);
            if (response.data.data.access_token) {
                setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn, response.data.data.menu, response.data.data.tac, response.data.data.tacv, response.data.data.research);
                props.history.push('/');
            } else {
                setError("Error in login");
            }
        }).catch(error => {
            setLoading(false);
            setError("Innskráning tókst ekki!");
        });

    }

    const showRafraen = () => {
        setError(null);
        setTab1active(true);
        setTab3active(false);
    }

    const showKenni = () => {
        setError(null);
        setTab1active(false);
        setTab3active(true);
    }

    return (
        <main>
            <div className="innskraningssida">
                <h3 className="login">Innskráning</h3>
                <h2 className="login">Sjúklingar</h2>
                <ul className="loginmethod">
                    <li className={tab1active ? 'rafraen active' : 'rafraen'} onClick={showRafraen}>Rafræn skilríki í síma</li>
                    <li className={tab3active ? 'kenni active' : 'kenni'} onClick={showKenni}>Innskráning</li>
                </ul>

                <div className={tab1active ? 'tabcontent active' : 'tabcontent'} id="tab1">
                    <div className={errorinraf ? 'advorun active' : 'advorun'} >Rann út á tíma / Notandi hætti við</div>
                    <div style={{ marginTop: 5 }} className={rafinn ? 'farsimanumer unactive' : 'farsimanumer'}>
                        <label htmlFor='farsimanumer'>Símanúmer</label>
                        <input type="text" {...farsimanumer} id="farsimanumer" required placeholder='000-0000' pattern="[0-9]{7,7}" />
                        <div className='errormsg'>{error}</div>
                    </div>
                    <div className={rafinn ? 'oryggistalan active' : 'oryggistalan'}>
                        Öryggistalan þín er:<br/>
                        <strong>{myoryggistalan}</strong>
                    </div>
                    <div className={rafinn ? 'oryggistalaninfo active' : 'oryggistalaninfo'}>
                        <p>Staðfestu auðkenninguna ef öryggistalan er sú sama og birtist á símanum þínum.</p>
                        <p>Ath. að öryggistalan er ekki PIN-númerið á skilríkjunum þínum.</p>
                    </div>
                    <div className={rafinn ? 'loader active' : 'loader'}><div className="custom-loader"></div></div>
                    <div style={{ marginTop: 15 }}><input type="button"  disabled={loading} className="loginbtn " value={loading ? 'Hleð...' : 'Auðkenna'} onClick={handleLoginRaf} /><br /></div>
                    <div className="innskraning_onnur">Innskráning fyrir <a href="/innskra">Fagfólk</a></div>
                </div>

                <div className={tab3active ? 'tabcontent active' : 'tabcontent'} id="tab3">
                    <div style={{ marginTop: 10 }}>
                        <input type="text" {...username} placeholder="Kennitala" />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <input type="password" {...password} placeholder="Lykilorð" />
                    </div>
                    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    <div style={{ marginTop: 5 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLogin} disabled={loading} /><br /></div>
                    <div className="gleymt"><a href="/endurstilla-lykilord">Gleymt lykilorð!</a></div>
                    <div className="innskraning_onnur">Innskráning fyrir <a href="/innskra">Fagfólk</a></div>
                </div>
            </div>
        </main>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login;