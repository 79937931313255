import React, { Component } from 'react';

import { getName } from  '../../../Utils/admincommon'
import { withTranslation} from 'react-i18next';

const Name = getName();

export class SystemHeader extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="admin-system-header">
                <h1>{t(this.props.title)}</h1>

                <div className="userprofile">
                    <a href="/admin/minsida" title={Name}><i className="fa-light fa-circle-user"></i></a>
                </div>
            </div>
        )
    }
}

export default withTranslation()(SystemHeader);