import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import { withTranslation } from 'react-i18next';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken} from '../../Utils/Common';
import { encrypt, decrypt } from '../helpers/decrypt'

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            isLoaded: false,
            name: "",
            kyn: "",
            tel: "",
            ssn: "",
            address:"",
            netfang: "",
            allergies: "",
            doctor:"",
            doctor_email:"",
            menntunarstig:"",
            husdyr:"",
            mengandiumhverfi:"",
            adrirsjukdomar:"",
            haed:"",
            thyngd:"",
            physician: "",
            healthcare: "",
            textiatakka: "Vista",
        };

        this.setHaed = this.setHaed.bind(this);
        this.setThyngd = this.setThyngd.bind(this);
        this.setPhysician = this.setPhysician.bind(this);
        this.setHealthcare = this.setHealthcare.bind(this);
        this.setAllergies = this.setAllergies.bind(this);

        this.setMenntunarstig = this.setMenntunarstig.bind(this);
        this.setHusdyr = this.setHusdyr.bind(this);
        this.setMengandiumhverfi = this.setMengandiumhverfi.bind(this);
        this.setAdrirsjukdomar = this.setAdrirsjukdomar.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        axios.get(global.config.api.url.live + '/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({
            posts: res.data.data.user,
            isLoaded: true,
            name: res.data.data.user[0].name,
            kyn: res.data.data.user[0].kyn,
            tel: res.data.data.user[0].tel,
            ssn: res.data.data.user[0].ssn,
            netfang: res.data.data.user[0].netfang,
            address: res.data.data.user[0].address,
            allergies: decrypt(res.data.data.user[0].allergies),
            doctor: res.data.data.user[0].doctor,
            doctor_email: res.data.data.user[0].doctor_email,
            menntunarstig: res.data.data.user[0].menntunarstig,
            husdyr: res.data.data.user[0].husdyr,
            mengandiumhverfi: res.data.data.user[0].mengandiumhverfi,
            adrirsjukdomar: res.data.data.user[0].adrirsjukdomar,
            haed:res.data.data.user[0].haed,
            thyngd:res.data.data.user[0].thyngd,
            physician: decrypt(res.data.data.user[0].physician) || null,
            healthcare: decrypt(res.data.data.user[0].healthcare) || null,
            textiatakka: "Vista",

        }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    setHaed(event) {  this.setState({haed: event.target.value}); }
    setThyngd(event) {  this.setState({thyngd: event.target.value}); }
    setPhysician(event) {  this.setState({physician: event.target.value}); }
    setHealthcare(event) {  this.setState({healthcare: event.target.value}); }
    setAllergies(event) {  this.setState({allergies: event.target.value}); }

    setMenntunarstig(event) {  this.setState({menntunarstig: event.target.value}); }
    setHusdyr(event) {  this.setState({husdyr: event.target.value}); }
    setMengandiumhverfi(event) {  this.setState({mengandiumhverfi: event.target.value}); }
    setAdrirsjukdomar(event) {  this.setState({adrirsjukdomar: event.target.value}); }

    handleSubmit(event) {
        event.preventDefault();
        const { address, allergies, menntunarstig, husdyr, mengandiumhverfi, adrirsjukdomar, haed, thyngd, physician, healthcare} = this.state;
        try {
            fetch(global.config.api.url.live + "/user", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    address: address,
                    haed: haed,
                    thyngd: thyngd,
                    adrirsjukdomar: adrirsjukdomar,
                    menntunarstig: menntunarstig,
                    husdyr: husdyr,
                    mengandiumhverfi: mengandiumhverfi,
                    allergies: encrypt(allergies),
                    myhealthcare: encrypt(healthcare),
                    mydr: encrypt(physician),
                }),
            }).then((response) => {
                if (response.status === 201) {
                    window.location = "/notandi/minsida";
                }
            });

        } catch (err) {
            console.log(err);
        }
        console.log(healthcare);
        this.setState({ textiatakka: "Er að uppfæra" })
    }

    render() {
        const { t } = this.props;
        const {isLoaded, name, kyn, tel, ssn, netfang,address, allergies, doctor, doctor_email, menntunarstig, husdyr, mengandiumhverfi, adrirsjukdomar, haed, thyngd, physician, healthcare, textiatakka} = this.state;

        if (isLoaded) {
            let kynid = "Kvár";
            if (kyn === 1) {
                kynid = "Karl";
            } else if (kyn === 2) {
                kynid = "Kona";
            }
            return (
                <div>
                    <Topbar title="menu.mypage" heiti=""/>
                    <main>
                        <div className="yfirlit">
                            <div className="fiftycent">
                                <h3 className="section_heading"> {t('home.general')}</h3>
                                <table className="user_table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nafn</td>
                                            <td>{decrypt(name) || null}</td>
                                        </tr>
                                        <tr>
                                            <td>Kennitala</td>
                                            <td>{decrypt(ssn) || null}</td>
                                        </tr>
                                        <tr>
                                            <td>Kyn</td>
                                            <td>{kynid}</td>
                                        </tr>
                                        <tr>
                                            <td>Netfang</td>
                                            <td>{decrypt(netfang) || null}</td>
                                        </tr>
                                        <tr>
                                            <td>Símanúmer</td>
                                            <td>{decrypt(tel) || null}</td>
                                        </tr>
                                        <tr>
                                            <td>Heimilisfang</td>
                                            <td>{decrypt(address) || null}</td>
                                        </tr>
                                        <tr>
                                            <td>Hæð</td>
                                            <td className='tr'><input type='number' value={haed} id="haed" name="haed" className='small' onChange={this.setHaed} onKeyDown={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}}/> cm</td>
                                        </tr>
                                        <tr>
                                            <td>Þyngd</td>
                                            <td className='tr'><input type='number' value={thyngd} id="thyngd" name="thyngd" className='small' onChange={this.setThyngd} onKeyDown={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault(); }}}/> Kg</td>
                                        </tr>

                                        <tr>
                                            <td>Menntunarstig</td>
                                            <td className='tr'>
                                                <select value={menntunarstig}  onChange={this.setMenntunarstig}>
                                                    <option value={0}>Veldu</option>
                                                    <option value={1}>Grunnskólapróf</option>
                                                    <option value={2}>Framhaldsmenntun</option>
                                                    <option value={3}>Háskólapróf</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Eru húsdýr á heimilinu?</td>
                                            <td className='tr'>
                                                <select value={husdyr} onChange={this.setHusdyr}>
                                                    <option value={0}>Nei</option>
                                                    <option value={1}>Já</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Mengandi umhverfi</td>
                                            <td className='tr'>
                                                <select value={mengandiumhverfi} onChange={this.setMengandiumhverfi}>
                                                    <option value={0}>Veldu</option>
                                                    <option value={1}>Iðnaður</option>
                                                    <option value={2}>Mygla</option>
                                                    <option value={3}>Annað</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Aðrir langvinnir sjúkdómar</td>
                                            <td><input value={adrirsjukdomar}  onChange={this.setAdrirsjukdomar} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div><div className="fiftycent">
                                <h3 className="section_heading"> Upplýsingar</h3>
                                <table className="user_table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Læknir</td>
                                            <td>{decrypt(doctor)} <a href={"mailto:" + decrypt(doctor_email)}><i className="fa-light fa-envelope"></i></a></td>
                                        </tr>
                                        <tr>
                                            <td>Ofnæmisupplýsingar</td>
                                            <td><input value={allergies || null} id="allergies" name="allergies" className='fw' onChange={this.setAllergies}/></td>
                                        </tr>
                                        <tr>
                                            <td>Heilbrigðisstofnun</td>
                                            <td><input value={healthcare || null} id="healthcare" name="healthcare" className='fw' onChange={this.setHealthcare}/></td>
                                        </tr>
                                        <tr>
                                            <td>Heimilislæknir</td>
                                            <td><input value={physician} id="physician" name="physician" className='fw' onChange={this.setPhysician}/></td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                            <button className="uppfaeranotenda" id="uppfaeranotenda" onClick={this.handleSubmit}> {textiatakka}</button>
                        </div>

                    </main>
                </div>
            )
        }else {
            return (
                <main></main>
            )
        }
    }
}

export default withTranslation()(User);