import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import { getResearch, getInMenu } from '../../Utils/Common';

const research = getResearch();
const menu = getInMenu();

export class Tasks extends Component {

    render() {
        return (
            <div>
                <Topbar title="menu.samskipti" heiti="" />
                <main>
                    <table className='qualityoflife'>
                        <thead>
                            <tr>
                                <th>Samskipti</th>
                                <th>Staða</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Leyfisskilmálar</td>
                                <td>Þú hefur samþykkt leyfisskilmálana okkar</td>
                                <td className='tr'><a href="/notandi/leyfisskilmalar" className='skodamat'>Skoða</a></td>
                            </tr>
                            {showinmenu(menu,4) &&
                                <tr>
                                    <td>IgExpert - Samþykki vegna þátttöku í rannsókn</td>
                                    {research === "1" &&
                                        <td>Ég samþykki að taka þátt í þessari rannsókn</td>
                                    }
                                    {research === "0" &&
                                        <td>Á eftir að svara</td>
                                    }
                                    {research === "2" &&
                                        <td>Ég samþykki <strong>ekki</strong> að taka þátt í þessari rannsókn</td>
                                    }
                                    <td className='tr'><a href="/notandi/IgExpert/samþykki" className='skodamat'>Skoða</a></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </main>
            </div>
        )
    }
}

function showinmenu(menu,item) {
    if (menu === null) {
        return false;
    } else {
        let result = menu.includes(item);
        return result;
    }
}

export default Tasks;