import React, { Component } from 'react'

import axios from 'axios';
import { getToken } from  '../../../../Utils/admincommon';
import { withTranslation} from 'react-i18next';
import { decrypt, encrypt } from '../../../helpers/decrypt'

const token = getToken();

export class PatientForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            doctors: [],
            name: 0,
            kyn: "",
            tel: "",
            ssn: "",
            netfang: "",
            allergies: "",
            doctor:"",
            doctorid:"",
            menntunarstig:"",
            husdyr:"",
            mengandiumhverfi:"",
            adrirsjukdomar:"",
            haed:"",
            thyngd:"",
            mydr: "",
            myhealthcare: "",
            textiatakka: "Vista",
            accessto: "",
            a_haexpert: 0,
            a_lifsgaedi:0,
            a_medicine_reminder:0,
            a_igexpert:0,
        }

        this.setName = this.setName.bind(this);
        this.setNetfang = this.setNetfang.bind(this);
        this.setSSN = this.setSSN.bind(this);
        this.setKyn = this.setKyn.bind(this);
        this.setTel = this.setTel.bind(this);
        this.setAllergies = this.setAllergies.bind(this);
        this.setMYdr = this.setMYdr.bind(this);
        this.setMYhealthcare = this.setMYhealthcare.bind(this);
        this.setTextaATakka = this.setTextaATakka.bind(this);
        this.setHaed = this.setHaed.bind(this);
        this.setThyngd = this.setThyngd.bind(this);
        this.getIfisIn = this.getIfisIn.bind(this);
        this.setDRID = this.setDRID.bind(this);
        this.setMengun = this.setMengun.bind(this);
        this.setRocky = this.setRocky.bind(this);
        this.setSNOBB = this.setSNOBB.bind(this);
        this.setAS = this.setAS.bind(this);

        this.setAhaexpert = this.setAhaexpert.bind(this);
        this.setLifsgaedi = this.setLifsgaedi.bind(this);
        this.setAIgexpert = this.setAIgexpert.bind(this);
        this.setMedicinereminder = this.setMedicinereminder.bind(this);

        this.setSAhaexpert = this.setSAhaexpert.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {

        var config = {
            'Content-Type': 'application/json',
            'Authorization': token
        }

        axios.get(global.config.api.url.live + '/getpatient/'+this.props.userid, {
            param: {
                userid: this.props.userid
            },
            headers: config
        }, { crossdomain: true })
        .then(res => this.setState({
            isLoaded: true,
            doctors: res.data.data.doctors,
            user: this.props.userid,
            ssn: decrypt(res.data.data.user[0].ssn),
            kyn: res.data.data.user[0].kyn,
            tel: decrypt(res.data.data.user[0].tel),
            netfang: decrypt(res.data.data.user[0].netfang),
            name: decrypt(res.data.data.user[0].name),
            allergies: decrypt(res.data.data.user[0].allergies),
            haed:res.data.data.user[0].haed,
            thyngd:res.data.data.user[0].thyngd,
            mydr: decrypt(res.data.data.user[0].physician),
            myhealthcare: decrypt(res.data.data.user[0].healthcare),
            accessto: res.data.data.user[0].accessto,
            doctor:res.data.data.user[0].doctor,
            doctorid:res.data.data.user[0].doctorid,
            menntunarstig:res.data.data.user[0].menntunarstig,
            husdyr:res.data.data.user[0].husdyr,
            mengandiumhverfi:res.data.data.user[0].mengandiumhverfi,
            adrirsjukdomar:res.data.data.user[0].adrirsjukdomar,
        },this.getIfisIn(res.data.data.user[0].accessto) ) )
        .catch(error => {
			const response = error.response;
            console.log(response);
        });
        //this.getIfisIn();
    }

    setName(event) {  this.setState({name: event.target.value}); }
    setNetfang(event) {  this.setState({netfang: event.target.value}); }
    setSSN(event) {  this.setState({ssn: event.target.value}); }
    setKyn(event) {  this.setState({kyn: event.target.value}); }
    setTel(event) {  this.setState({tel: event.target.value}); }
    setAllergies(event) {  this.setState({allergies: event.target.value}); }
    setMYdr(event) {  this.setState({mydr: event.target.value}); }
    setMYhealthcare(event) {  this.setState({myhealthcare: event.target.value}); }
    setTextaATakka(p) {  this.setState({textiatakka: p}); }
    setHaed(event) {  this.setState({haed: event.target.value}); }
    setThyngd(event) {  this.setState({thyngd: event.target.value}); }
    setDRID(event) {  this.setState({doctorid: event.target.value}); }
    setMengun(event) {  this.setState({mengandiumhverfi: event.target.value}); }
    setRocky(event) {  this.setState({husdyr: event.target.value}); }
    setSNOBB(event) {  this.setState({menntunarstig: event.target.value}); }
    setAS(event) {  this.setState({adrirsjukdomar: event.target.value}); }
    
    setAhaexpert() {
        const {a_haexpert} = this.state;
        let newSet = 0;
        if (a_haexpert === 1){
            newSet = 0;
        } else {
            newSet = 1;
        }
        this.setState({a_haexpert: newSet});
    }
    setLifsgaedi() {
        const {a_lifsgaedi} = this.state;
        let newSet = 0;
        if (a_lifsgaedi === 1){
            newSet = 0;
        } else {
            newSet = 1;
        }
        this.setState({a_lifsgaedi: newSet});
    }
    setAIgexpert() {
        const {a_igexpert} = this.state;
        let newSet = 0;
        if (a_igexpert === 1){
            newSet = 0;
        } else {
            newSet = 1;
        }
        this.setState({a_igexpert: newSet});
    }
    setMedicinereminder() {
        const {a_medicine_reminder} = this.state;
        let newSet = 0;
        if (a_medicine_reminder === 1){
            newSet = 0;
        } else {
            newSet = 1;
        }
        this.setState({a_medicine_reminder: newSet});
    }
    setSAhaexpert() {  this.setState({a_haexpert: 1}); }

    getIfisIn(accesstostring) {
        if (accesstostring.includes(1)) {
            this.setState({a_haexpert: 1});
        }
        if (accesstostring.includes(2)) {
            this.setState({a_lifsgaedi: 1});
        }
        if (accesstostring.includes(3)) {
            this.setState({a_medicine_reminder: 1});
        }
        if (accesstostring.includes(4)) {
            this.setState({a_igexpert: 1});
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const { name, netfang, tel, kyn, ssn, user, allergies, mydr, myhealthcare, a_haexpert,a_lifsgaedi,a_medicine_reminder,a_igexpert, haed, thyngd, doctorid, adrirsjukdomar, menntunarstig, husdyr, mengandiumhverfi} = this.state;
        try {
            fetch(global.config.api.url.live + "/getpatient/"+user, {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    name: encrypt(name),
                    netfang: encrypt(netfang),
                    ssn: encrypt(ssn),
                    kyn: kyn,
                    haed: haed,
                    thyngd: thyngd,
                    doctorid: doctorid,
                    adrirsjukdomar: adrirsjukdomar,
                    menntunarstig: menntunarstig,
                    husdyr: husdyr,
                    mengandiumhverfi: mengandiumhverfi,
                    tel: encrypt(tel),
                    userid: user,
                    allergies: encrypt(allergies),
                    mydr: encrypt(mydr),
                    myhealthcare: encrypt(myhealthcare),
                    access_to_1: a_haexpert,
                    access_to_2: a_lifsgaedi,
                    access_to_3: a_medicine_reminder,
                    access_to_4: a_igexpert,
                }),
            }).then((response) => {
                if (response.status === 201) {
                    window.location = "/admin/patients/" + user;
                }
            });

        } catch (err) {
            console.log(err);
        }
    };

    render() {
        //accessto
        const { isLoaded,doctors, name, netfang, tel, kyn, ssn, user, allergies, mydr, myhealthcare, textiatakka,haed,thyngd, a_haexpert,a_lifsgaedi,a_medicine_reminder,a_igexpert,doctorid,menntunarstig,husdyr,mengandiumhverfi,adrirsjukdomar} = this.state;
        if (isLoaded) {
            return (
                    <form onSubmit={this.handleSubmit}>
                        <div className="sjuklingsform">
                            <div className='sjuklingur_upplysingar pr20'>
                                <h3>Upplýsingar</h3>
                                <div className='sextiu'>
                                    <div className='label'>
                                        <label htmlFor="name">Nafn</label>
                                    </div><div className='input'>
                                        <input type="text" placeholder="Nafn" value={name} id="name" onChange={this.setName} />
                                    </div>
                                </div><div className='fjortiu pr0'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Kennitala (má ekki breyta)</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Kennitala" value={ssn} id="ssn" readOnly  />
                                    </div>
                                </div>
                                <div className='thjatiu'>
                                    <div className='label'>
                                        <label htmlFor="kyn">Kyn</label>
                                    </div><div className='input'>
                                        <select value={kyn}  id="kyn" onChange={this.setKyn}>
                                            <option value="1">Karl</option>
                                            <option value="2">Kona</option>
                                            <option value="3">Kvár</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='thjatiu'>
                                    <div className='label'>
                                        <label htmlFor="tel">Símanúmer</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Símanúmer" value={tel} id="tel" onChange={this.setTel} />
                                    </div>
                                </div><div className='fjortiu pr0'>
                                    <div className='label'>
                                        <label htmlFor="netfang">Netfang</label>
                                    </div><div className='input'>
                                        <input type="text" placeholder="Netfang" value={netfang} id="netfang" onChange={this.setNetfang} />
                                    </div>
                                </div>
                                <div className='thjatiu'>
                                    <div className='label'>
                                        <label htmlFor="netfang">Hæð</label>
                                    </div><div className='input'>
                                        <input type="text" className='medlysingu' placeholder="Hæð" value={haed} id="haed" onChange={this.setHaed} />
                                        <div className='inputLysing'>cm</div>
                                    </div>
                                </div><div className='thjatiu'>
                                    <div className='label'>
                                        <label htmlFor="netfang">Þyngd</label>
                                    </div><div className='input'>
                                        <input type="text" className='medlysingu' placeholder="þyngd" value={thyngd} id="thyngd" onChange={this.setThyngd}/>
                                        <div className='inputLysing'>Kg</div>
                                    </div>
                                </div><div className='fjortiu pr0'>
                                    <div className='label'>
                                        <label htmlFor="netfang">Læknir</label>
                                    </div><div className='input'>
                                        <select id="doctorid" name="doctorid" onChange={this.setDRID} value={doctorid} className='fw'>
                                        {doctors.map(doctor =>
                                            <option value={doctor.id}>{decrypt(doctor.name)} </option>
                                        )}
                                        </select>
                                    </div>
                                </div>
                                <div className='thjatiu'>
                                    <label htmlFor="mengandiumhverfi">Mengandi umhverfi</label>
                                    <select value={mengandiumhverfi} id='mengandiumhverfi' className='fw' onChange={this.setMengun}>
                                        <option value={0}>Nei</option>
                                        <option value={1}>Iðnaður</option>
                                        <option value={2}>Mygla</option>
                                        <option value={3}>Annað</option>
                                    </select>
                                </div><div className='thjatiu'>
                                    <label htmlFor="husdyr">Húsdýr á heimilinu?</label>
                                    <select value={husdyr} id='husdyr' onChange={this.setRocky}>
                                        <option value={0}>Nei</option>
                                        <option value={1}>Já</option>
                                    </select>
                                </div><div className='fjortiu pr0'>
                                    <label htmlFor="menntunarstig">Menntunarstig</label>
                                    <select value={menntunarstig} id='menntunarstig' className='fw' onChange={this.setSNOBB}>
                                        <option value={0}>Veldu</option>
                                        <option value={1}>Grunnskólapróf</option>
                                        <option value={2}>Framhaldsmentun</option>
                                        <option value={3}>Háskólapróf</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="adrirsjukdomar">Aðrir langvinnir sjúkdómar</label>
                                    <input type="text"  placeholder="Aðrir langvinnir sjúkdómar" value={adrirsjukdomar} id="adrirsjukdomar" onChange={this.setAS}  />
                                </div>
                                <div className='fimmtiu'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Heimilislæknir</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Heimilislæknir" value={mydr} id="mydr" onChange={this.setMYdr}  />
                                    </div>
                                </div><div className='fimmtiu pr0'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Heilsugæsla</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Heilsugæsla" value={myhealthcare} id="myhealthcare" onChange={this.setMYhealthcare}  />
                                    </div>
                                </div>
                                <div className='linaniformi'>
                                    <div className='label'>
                                        <label htmlFor="ssn">Ofnæmi</label>
                                    </div><div className='input'>
                                        <input type="text"  placeholder="Ofnæmi" value={allergies} id="allergies" onChange={this.setAllergies}  />
                                    </div>
                                </div>
                                <input type="hidden" value={user} id="user" readOnly />
                                <input type="hidden" id="a_haexpert" value={a_haexpert} readOnly />
                                <input type="hidden" id="a_lifsgaedi" value={a_lifsgaedi} readOnly />
                                <input type="hidden" id="a_medicine_reminder" value={a_medicine_reminder} readOnly />
                                <input type="hidden" id="a_igexpert" value={a_igexpert} readOnly />
                            </div><div className="sjuklingur_aukaupplysingar pl20">
                                
                                <h3>Aðgangur</h3>
                                
                                <div className='adgengi'>
                                    HAExpert
                                    <div className={"ermedleyfi t_" + a_haexpert} onClick={this.setAhaexpert}></div>
                                </div>
                                <div className='adgengi'>
                                    Lífsgæði<div className={"ermedleyfi t_" + a_lifsgaedi} onClick={this.setLifsgaedi}></div>
                                </div>
                                <div className='adgengi'>
                                    Lyfjadagbók
                                    <div className={"ermedleyfi t_" + a_medicine_reminder} onClick={this.setMedicinereminder}></div>
                                </div>
                                <div className='adgengi'>
                                    IgExpert
                                    <div className={"ermedleyfi t_" + a_igexpert} onClick={this.setAIgexpert}></div>
                                </div>
                            </div>
                            <button type="submit" className="buatilnotenda" id="uppfaeranotenda" onClick={() => this.setState({ textiatakka: "Er að uppfæra" })}> {textiatakka}</button>
                        </div>
                    </form>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(PatientForm);