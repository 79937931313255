import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';

import { getToken, getResearch,setResearch } from '../../Utils/Common';
const token = getToken();
const research = getResearch();

export class IgExpert extends Component {

    handleaccept = () => {
        axios.get(global.config.api.url.live + '/acceptresearch', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        }).then(response => {
            setResearch(1);
            window.location.reload();
        }).catch(error => {});
    }

    handledecline = () => {
        axios.get(global.config.api.url.live + '/declineresearch', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        }).then(response => {
            setResearch(2);
            window.location.reload();
        }).catch(error => {});
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Topbar title={t('menu.igexpert')} heiti=""/>
                <main>
                    <h1>Upplýst samþykki vegna þátttöku í rannsókn</h1>
                    <p>Einstaklingsmiðuð samráðsmeðferð mótefnagjafa með stuðningi IgXpert á Landspítala. {research}</p>
                    <p>Ég staðfesti með undirskrift minni að:</p>
                    <p>➢ Ég hef lesið kynningarbréf undirritað af Birni Rúnari Lúðvíkssyni varðandi rannsóknina og fengið svör við spurningum mínum.</p>
                    <p>➢ Mér hefur verið kynnt eðli og umfang rannsóknarinnar. Ég veiti samþykki fyrir þátttöku minni í þessari rannsókn.  Samþykkið felur í sér eftirfarandi:</p>
                    <ol type="a">
                        <li>Svara virkni- (IgXpert) og lífsgæðamati á 3 mánaða fresti meðan á rannsóknartímabili stendur.</li>
                        <li>Svara stöðluðum spurningalista rannsóknarinnar varðandi notendavænleika kerfisins eftir 3, 6 og 12 mánaða samfellda notkun.</li>
                        <li>Að svara stöðluðum spurningalista rannsóknarinnar varðandi bakgrunnsupplýsingar þátttakenda og mögulegum hliðarverkunum meðferðarinnar.</li>
                        <li>Að veita rannsakendum aðgang að sjúkraskrárupplýsingum varðandi fyrirliggjandi niðurstöður virkni – og lífsgæðamati innan Heilsugáttar, auk blóðrannsóknar niðurstöðum til að meta ónæmissvar, magn mótefna og blóðrannsóknaniðurstöður varðandi mögulegar hliðarverkanir lyfjameðferðarinnar.</li>
                        <li>Að færa ofangreindar upplýsingar inn í dulkóðaðan rannsóknargagnagrunn rannsóknarinnar sem verður vistaður innan Landspítalans til frambúðar til áframhaldandi rannsókna sem hafa fengið leyfi Vísindasiðanefndar.</li>
                    </ol>
                    <p>➢ Mér hefur verið gert það ljóst að sem sjálfboðaliði get ég afþakkað þátttöku í rannsókninni og enn fremur hætt þátttöku hvenær sem er.</p>
                    <p>➢ Þátttakendur geta krafist þess að viðkomandi heilbrigðisupplýsingum um sig sé eytt. Þó er ekki hægt að eyða slíkum upplýsingum eftir að þær hafa verið gerðar ópersónugreinanlegar, eða ef upplýsingarnar eru þegar orðnar hluti af niðurstöðum rannsóknar.</p>
                    <button onClick={this.handleaccept} className='ig_sammthykki'>Samþykki</button>
                    <button onClick={this.handledecline} className='ig_sammthykkiekki'>Samþykki ekki</button>
                </main>
            </div>
        )
    }
}

export default withTranslation()(IgExpert);