import React, { Component } from 'react'
import SystemHeader from './SystemHeader';

document.body.classList.add("admin");

export class Samskipti extends Component {

    render() {
        return (
            <div>
                <SystemHeader title="Samskipti" />
                Er að breyta kerfinu
            </div>
        )
    }
}

export default Samskipti