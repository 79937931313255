import React, { Component } from 'react'
import SystemHeader from './SystemHeader';
import { encrypt } from '../../helpers/decrypt'

import { getToken} from  '../../../Utils/admincommon'
const token = getToken();

document.body.classList.add("admin");

export class AddStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            name: "",
            tel: "",
            ssn: "",
            netfang: "",
            starfsheiti: "",
            starf: "2",
            staff:"0",
            lykilord:"",
            textiatakka:"Bæta við fagaðila"
        }

        this.setName = this.setName.bind(this);
        this.setNetfang = this.setNetfang.bind(this);
        this.setSSN = this.setSSN.bind(this);
        this.setTel = this.setTel.bind(this);
        this.setStarfsheiti = this.setStarfsheiti.bind(this);
        this.setStarf = this.setStarf.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setLykilord = this.setLykilord.bind(this);
    }

    setName(event) {  this.setState({name: event.target.value}); }
    setNetfang(event) {  this.setState({netfang: event.target.value}); }
    setSSN(event) {  this.setState({ssn: event.target.value}); }
    setTel(event) {  this.setState({tel: event.target.value}); }
    setStarfsheiti(event) {  this.setState({starfsheiti: event.target.value}); }
    setStarf(event) {  this.setState({starf: event.target.value}); }
    setLykilord(event) {  this.setState({lykilord: event.target.value}); }

    handleSubmit(event) {
        event.preventDefault();
        const { name, netfang, tel, ssn, starfsheiti, staff,starf, lykilord} = this.state;
        this.setState({ textiatakka: "Er að bæta við" })

        try {
            fetch(global.config.api.url.live + "/getstaff", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    name: encrypt(name),
                    netfang: encrypt(netfang),
                    ssn: encrypt(ssn),
                    tel: encrypt(tel),
                    starfsheiti: encrypt(starfsheiti),
                    staff: staff,
                    role: starf,
                    lykilord: lykilord
                }),
            }).then((response) => {
                if (response.status === 200) {
                    window.location = "/admin/staff/";
                }
            });

        } catch (err) {
            console.log(err);
        }

    }

    render() {
        const { name, netfang, tel, ssn, starf, starfsheiti, textiatakka, lykilord} = this.state;
        return (
            <div>
                <SystemHeader title="Starfsfólk" />
                <div className="yfirlit">
                    <form>
                        <div className='sjuklingsform'>
                            <label>Nafn</label>
                            <input type="text" name="name" value={name} onChange={this.setName}/>
                            <label>Starfsheiti</label>
                            <input type="text" name="name" value={starfsheiti} onChange={this.setStarfsheiti}/>
                            <div className='sjotiu'>
                                <label>Netfang</label>
                                <input type="text" name="netfang" value={netfang} onChange={this.setNetfang}/>
                            </div><div className='thjatiu pr0'>
                                <label>Sími</label>
                                <input type="text" name="tel"  value={tel} onChange={this.setTel}/>
                            </div>
                            <div className='thjatiu'>
                                <label>Kennitala</label>
                                <input type="text" name="tel"  value={ssn} onChange={this.setSSN}/>
                            </div><div className='thjatiu'>
                                <label>Lykilorð</label>
                                <input type="text" name="tel"  value={lykilord} onChange={this.setLykilord}/>
                            </div><div className='fjortiu pr0'>
                                <label>Hlutverk</label>
                                <select name="role" value={starf} className='fw' onChange={this.setStarf}>
                                    <option value="1">Admin</option>
                                    <option value="2">Læknir</option>
                                    <option value="3">Hjúkrunarfræðingur</option>
                                </select>
                            </div>
                            <button type="submit" className="buatilnotenda" id="uppfaeranotenda" onClick={this.handleSubmit}> {textiatakka}</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default AddStaff