import React from 'react'

import { encrypt } from '../helpers/decrypt'

function UserChange() {

    return (
        <main>
            <div >
                <h3 className="login">Breyta 15</h3>
                <table className="user_table">
                    <tr>
                        <td>Dóra Lúðvíksdóttir</td>
                        <td>{encrypt("Dóra Lúðvíksdóttir")}</td>
                    </tr>
                    <tr>
                        <td>doralud@landspitali.is</td>
                        <td>{encrypt("doralud@landspitali.is")}</td>
                    </tr>
                    <tr>
                        <td>8963049</td>
                        <td>{encrypt("8963049")}</td>
                    </tr>
                    <tr>
                        <td>0402573579</td>
                        <td>{encrypt("0402573579")}</td>
                    </tr>
                    <tr>
                        <td>Læknir</td>
                        <td>{encrypt("Læknir")}</td>
                    </tr>
                </table>
            </div>
        </main>
    );
}

export default UserChange;