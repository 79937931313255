import React, { useEffect, useState } from 'react'

function Footer() {

    const [offset, setOffset] = useState(0);
    var fname = "onmove";
    var halfway = "halfway";
//    var x = document.getElementById("adgerdir");

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, []);

    if (offset > 60) {
        document.body.classList.add(halfway);
    } else {
        document.body.classList.remove(halfway);
    }

    if (offset > 100) {
        document.body.classList.add(fname);
    } else {
        document.body.classList.remove(fname);
    }
    return (
        <div className="wrapper">
            <footer>
                <div className="siteinfo">© 2024 Fruma</div>
                <div className="contactinfo"><a href="mailto:fruma@fruma.is">fruma@fruma.is</a></div>
            </footer>
        </div>
    );
}

export default Footer;