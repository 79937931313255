import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken } from '../../Utils/Common';
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class HAEQualityOfLife extends Component {
    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/qualityoflife/all/', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.qol, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <Topbar title="Lífsgæðalistinn" heiti=""/>
                        <main>
                        <h1>Mat á lífsgæðum <Link to="/notandi/hae/lifsgaedi/new" className="addUrgency"><i className="fa-solid fa-square-plus"></i> Skrá mat</Link></h1>
                        <table className='qualityoflife'>
                            <thead>
                                <tr>
                                    <th colSpan="2"></th>
                                    <th className='tl hideonmobile'>Verkir og bólga</th>
                                    <th className='tl hideonmobile'>Líkamleg virkni</th>
                                    <th className='tl hideonmobile'>Andleg líðan</th>
                                    <th className='tl hideonmobile'>Almenn líðan</th>
                                    <th className='tl'></th>
                                </tr>
                            </thead>
                            <tbody>
                            {posts.map(post =>
                                <tr key={post.id}>
                                    <td width="1%"><div className='heildarskor_mynd'><i className={getHeildarskor(post.score)}></i></div></td>
                                    <td >Heildarskor <strong className={getColorUp(post.score)}>{post.score}%</strong><br/>Skráð {moment(post.createdon).format('Do MMM YYYY')}</td>
                                    <td className='tl hideonmobile'>
                                        <strong className={getColor(100 - post.verkirtotal) + " samtalstala"}>{100 - post.verkirtotal}% </strong>
                                        <div className='utkoma'>
                                            {getVerkir(post.q1)} verkir í stoðkerfi <br/>
                                            {getVerkir(post.q2)} kviðverkir<br/>
                                            {getVerkur(post.q3)} höfuðverkur
                                        </div>
                                    </td>
                                    <td className='tl hideonmobile'>
                                        <strong className={getColorUp(post.virknitotal) + " samtalstala"}>{post.virknitotal}%</strong>
                                        <div className='utkoma'>
                                            {getThreyta(post.q4)} þreyta<br/>
                                            {getThreyta(post.q5)} mæði<br/>
                                            {getGeta(post.q6)} almenn færni
                                        </div>
                                    </td>
                                    <td className='tl hideonmobile'>
                                        <strong className={getColorUp(post.andlegtotal) + " samtalstala"}>{post.andlegtotal}%</strong>
                                        <div className='utkoma'>
                                            {getKvidi(post.q7)} kvíði<br/>
                                            {getThunglyndi(post.q8)} þunglyndi
                                        </div>
                                    </td>
                                    <td className='tl hideonmobile'>
                                        <strong className={getColorUp(post.almenntotal) + " samtalstala"}>{post.almenntotal}%</strong>
                                        <div className='utkoma'>
                                            {getLidan(post.q9)} líðan<br/>
                                            {getSvefn(post.q10)} svefngæði
                                        </div>
                                    </td>
                                    <td className="tl"><a href={"/notandi/hae/lifsgaedi/v/" + post.id} className='skodamat'>Skoða mat</a></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div className="urgencies">
                        <h3>{t('menu.lifsgaedi')} <Link to="/notandi/hae/lifsgaedi/"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <p>Þú hefur ekki skráð lífsgæðamat.</p>
                        <div className="nyttprof">
                            <a href='/notandi/hae/lifsgaedi/'>Skrá mat</a>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function getColor(p) {
    let mycolor = "litur_4";
    
    if (p === 0) {
        mycolor = "litur_0";
    } else if (p > 0 && p <= 24) {
        mycolor = "litur_1";
    } else if (p >= 25 && p <= 49) {
        mycolor = "litur_2";
    } else if (p >= 50 && p <= 74) {
        mycolor = "litur_3";
    } else if (p >= 75) {
        mycolor = "litur_4";
    }

    return mycolor;
}

function getColorUp(p) {
    let mycolor = "litur_0";

    if (p <= 25 && p >= 0) {
        mycolor = "litur_4";
    } else if (p > 25 && p <= 50) {
        mycolor = "litur_3";
    } else if (p > 50 && p <= 75) {
        mycolor = "litur_2";
    } else if (p > 75 && p <= 99) {
        mycolor = "litur_1";
    } else if (p >= 100) {
        mycolor = "litur_0";
    }

    return mycolor;
}

function getVerkir(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Engir";
    } else if (p === 1) {
        verkir = "Vægir";
    } else if (p === 2) {
        verkir = "Þónokkrir";
    } else if (p === 3) {
        verkir = "Miklir";
    } else if (p === 4) {
        verkir = "Verstu mögulegu";
    }
    return verkir;
}

function getVerkur(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Enginn";
    } else if (p === 1) {
        verkir = "Vægur";
    } else if (p === 2) {
        verkir = "Þónokkrur";
    } else if (p === 3) {
        verkir = "Mikill";
    } else if (p === 4) {
        verkir = "Versti mögulegi";
    }
    return verkir;
}

function getThreyta(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Engin";
    } else if (p === 1) {
        verkir = "Væg";
    } else if (p === 2) {
        verkir = "Þónokkur";
    } else if (p === 3) {
        verkir = "Mikil";
    } else if (p === 4) {
        verkir = "Versta mögulega";
    }
    return verkir;
}

function getGeta(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Full";
    } else if (p === 1) {
        verkir = "Mikil";
    } else if (p === 2) {
        verkir = "Þónokkur";
    } else if (p === 3) {
        verkir = "Væg";
    } else if (p === 4) {
        verkir = "Engin";
    }
    return verkir;
}

function getLidan(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Góð";
    } else if (p === 1) {
        verkir = "Ágæt";
    } else if (p === 2) {
        verkir = "Sæmileg";
    } else if (p === 3) {
        verkir = "Slæm";
    } else if (p === 4) {
        verkir = "Versta mögulega";
    }
    return verkir;
}

function getSvefn(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Góð";
    } else if (p === 1) {
        verkir = "Ágæt";
    } else if (p === 2) {
        verkir = "Sæmileg";
    } else if (p === 3) {
        verkir = "Slæm";
    } else if (p === 4) {
        verkir = "Verstu mögulegu";
    }
    return verkir;
}

function getThunglyndi(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Ekkert";
    } else if (p === 1) {
        verkir = "Vægt";
    } else if (p === 2) {
        verkir = "Þónokkurt";
    } else if (p === 3) {
        verkir = "Mikið";
    } else if (p === 4) {
        verkir = "Versta mögulega";
    }
    return verkir;
}

function getKvidi(p) {
    let verkir = "";
    if (p === 0) {
        verkir = "Enginn";
    } else if (p === 1) {
        verkir = "Vægur";
    } else if (p === 2) {
        verkir = "Þónokkur";
    } else if (p === 3) {
        verkir = "Mikill";
    } else if (p === 4) {
        verkir = "Versti mögulegi";
    }
    return verkir;
}

function getHeildarskor(heildarskor) {
    let heildarskorIcon = "";
    if (heildarskor <= 25 && heildarskor > 0) {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (heildarskor > 25 && heildarskor <= 50) {
        heildarskorIcon = "fa-regular fa-face-anguished";
    } else if (heildarskor > 50 && heildarskor <= 75) {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else if (heildarskor > 75 && heildarskor <= 99) {
        heildarskorIcon = "fa-regular fa-face-expressionless";
    } else if (heildarskor >= 100) {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}

export default withTranslation()(HAEQualityOfLife);