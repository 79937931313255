import React, { Component } from 'react'
import SystemHeader from './SystemHeader';
import axios from 'axios';

import { decrypt, encrypt } from '../../helpers/decrypt'

import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();
document.body.classList.add("admin");

export class Staff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            name: "",
            tel: "",
            ssn: "",
            netfang: "",
            starf: "2",
            starfsheiti: "",
            staff: this.props.match.params.id,
            textiatakka:"Uppfæra"
        }

        this.setName = this.setName.bind(this);
        this.setNetfang = this.setNetfang.bind(this);
        this.setSSN = this.setSSN.bind(this);
        this.setTel = this.setTel.bind(this);
        this.setStarf = this.setStarf.bind(this);
        this.setStarfsheiti = this.setStarfsheiti.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount () {
        var selectedUser = "";

        if(this.props.match.params.id !== undefined) {
            selectedUser = this.props.match.params.id;
        }

        axios.get(global.config.api.url.live + '/getstaff/'+ selectedUser,{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({
            isLoaded: true,
            name: decrypt(res.data.data.user[0].name),
            tel: decrypt(res.data.data.user[0].tel),
            ssn: decrypt(res.data.data.user[0].ssn),
            netfang: decrypt(res.data.data.user[0].netfang),
            starf: res.data.data.user[0].starf,
            starfsheiti: decrypt(res.data.data.user[0].role),
            staff: this.props.match.params.id
        }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/adminsessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    setName(event) {  this.setState({name: event.target.value}); }
    setNetfang(event) {  this.setState({netfang: event.target.value}); }
    setSSN(event) {  this.setState({ssn: event.target.value}); }
    setTel(event) {  this.setState({tel: event.target.value}); }
    setStarf(event) {  this.setState({starf: event.target.value}); }
    setStarfsheiti(event) {  this.setState({starfsheiti: event.target.value}); }

    handleSubmit(event) {
        event.preventDefault();
        const { name, netfang, tel, ssn, starfsheiti, staff,starf} = this.state;
        this.setState({ textiatakka: "Er að uppfæra" })

        /*
        $role = $jsonData->role;
        $staff = $jsonData->staff;
        */

        try {
            fetch(global.config.api.url.live + "/getstaff", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    name: encrypt(name),
                    netfang: encrypt(netfang),
                    ssn: encrypt(ssn),
                    tel: encrypt(tel),
                    starfsheiti: encrypt(starfsheiti),
                    staff: staff,
                    role: starf
                }),
            }).then((response) => {
                if (response.status === 200) {
                    window.location = "/admin/staff/" + staff;
                }
            });

        } catch (err) {
            console.log(err);
        }

    }

    render() {
        // staff
        const { name, netfang, tel, ssn, isLoaded, starfsheiti, textiatakka,starf} = this.state;
        if (isLoaded) {
        return (
            <div>
                <SystemHeader title="Starfsfólk" />
                <div className="yfirlit">
                    <form>
                        <div className='sjuklingsform'>
                            <label>Nafnið</label>
                            <input type="text" name="name" value={name} onChange={this.setName}/>
                            <label>Starfsheiti</label>
                            <input type="text" name="name" value={starfsheiti} onChange={this.setStarfsheiti}/>
                            <div className='sjotiu'>
                                <label>Netfang</label>
                                <input type="text" name="netfang" value={netfang} onChange={this.setNetfang}/>
                            </div><div className='thjatiu pr0'>
                                <label>Sími</label>
                                <input type="text" name="tel"  value={tel} onChange={this.setTel}/>
                            </div>
                            <div className='fimmtiu'>
                                <label>Kennitala</label>
                                <input type="text" name="tel"  value={ssn} onChange={this.setSSN}/>
                            </div><div className='fimmtiu pr0'>
                                <label>Hlutverk</label>
                                <select name="role" value={starf} className='fw' onChange={this.setStarf}>
                                    <option value="1">Admin</option>
                                    <option value="2">Læknir</option>
                                    <option value="3">Hjúkrunarfræðingur</option>
                                </select>
                            </div>
                            <button type="submit" className="buatilnotenda" id="uppfaeranotenda" onClick={this.handleSubmit}> {textiatakka}</button>
                        </div>
                    </form>
                </div>
            </div>
        )
        } else {
            return (
                <div>Ekkert</div>
            )
        }
    }
}

export default Staff