import React, { Component } from 'react'
import { withTranslation} from 'react-i18next';
import axios from 'axios';
import Urgencies from './Urgencies'
import Topbar from '../templates/Topbar';
import Heildarskor from './user/components/Heildarskor_nytt';
import { Link } from 'react-router-dom';

import UserGrafMonthByID from './user/components/UserGrafMonthByID';

import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession, getInMenu} from '../../Utils/Common';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

const menu = getInMenu();

export class Dashboard extends Component {
    state = { posts: [], isLoaded: false, userid: null };
    componentDidMount () {
        axios.get(global.config.api.url.live + '/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ userid: res.data.data.userid, isLoaded: true }))
        .catch(error => {
			const response = error.response;

            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { isLoaded, userid} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.yfirlit" heiti=""/>
                    <main>
                        <div className="yfirlit">
                        {showinmenu(menu,1) &&
                            <a className='skrakast_btn' href="/notandi/haexpert/skra"><strong>{t('inflammatory')}</strong><br/>{t('info.registeracase')}</a>
                        }
                        {showinmenu(menu,4) &&
                            <a className='skrakast_btn' href="/notandi/IgExpert/skra"><strong>Skrá tíðni sýkinga</strong><br/>Mótefnameðferð einstaklinga með ónæmisgalla</a>
                        }
                        {showinmenu(menu,1) &&
                            <a className='lokakostum_btn' href="/notandi/haexpert"><strong>Bólguköst</strong><br/>{t('info.infoclose')}</a>
                        }
                        {showinmenu(menu,499) &&
                            <a className='lokakostum_btn' href="/notandi/IgExpert"><strong>Skrá hliðarverkanir</strong><br/>Svara spurningalista</a>
                        }
                        {showinmenu(menu,2) &&
                            <a className='samskipti_btn' href="/notandi/hae/lifsgaedi/"><strong>Lífsgæðalistinn</strong><br/>Skrá lífsgæðamat</a>
                        }
                            <a className='timapantanir_btn' href="/notandi/fraedsluefni"><strong>Fræðsluefni</strong><br/>Hér getur þú fundið fræðsluefni</a>
                        {showinmenu(menu,1) &&
                            <h3 className="section_heading">{t('menu.haexpert')}</h3>
                        }
                        {showinmenu(menu,1) &&
                            <div className="alpha"><Urgencies/></div>
                        }
                        {showinmenu(menu,1) &&
                            <div className="gamma">
                                <UserGrafMonthByID userid={userid} />
                            </div>
                        }
                            <Heildarskor/>
                        {showinmenu(menu,4) &&
                        <div className="yfirlit">
                            <h3 className='section_heading'><Link to="/notandi/IgExpert/">{t('menu.igexpert')}</Link></h3>
                            <h4><strong>Tilfelli</strong></h4>
                        </div>
                        }
                        </div>
                    </main>
                </div>
            )
        } else {
            return (<div></div>);
        }
    }
}


function showinmenu(menu,item) {
    if (menu === null) {
        return false;
    } else {
        let result = menu.includes(item);
        return result;
    }
}

export default withTranslation()(Dashboard);