import React, { Component } from 'react'
import Topbar from '../templates/Topbar';

export class IgExpert extends Component {

    render() {
        return (
            <div>
                <Topbar title="Leyfisskilmálar" heiti=""/>
                <main>
                    <h2>Leyfisskilmálar</h2>
                    <p>Með notkun og/eða skráningu í Frumu samþykkir notandi án takmarkana þessa skilmála og lýsir því yfir að hafa lesið, skilið og samþykkt skilmálana í heild sinni.</p>
                    <p>Jafnframt viðurkennir notandi rétt Frumu til að breyta skilmálum þessum sem notandi er bundinn af. Komi til breytinga munu þær verða kynntar notanda með textaskilaboðum eða tölvupósti. Breyttir skilmálar taka gildi við tilkynningu.</p>
                    <p>Notandi samþykkir framangreindar aðferðir við upplýsingagjöf til hans. Um samning þennan og skilmála Frumu sem og öll mál sem rísa kunna af notkun Frumu, gilda ákvæði íslenskra laga. Rísi mál vegna brota á skilmálum þessum eða ágreinings um túlkun þeirra skal reka það fyrir Héraðsdómi Reykjavíkur.</p>
                    <p>Skilmálar þessir gilda frá 09.10.2024 og til þess tíma er nýir skilmálar taka gildi.</p>
                    <p>Frumu er ávallt heimilt að stöðva notkun og/eða loka á aðganga af eftirtöldum ástæðum:</p>
                    <p>Starfsemi notanda telst að mati Frumu sviksamleg á einhvern hátt eða notandi muni með háttsemi sinni skaða orðspor eða merki félagsins, að mati Frumu.</p>
                    <h4>Almennt um Frumu</h4>
                    <p>Fruma er vef app sem er notað til að safna saman upplýsingum til að hjálpa læknum við meðferð sjúkdóma og hjálpa sjúklingum að fá betri yfirsýn yfir sína heilsu og heilsufarssögu.</p>
                    <h4>Trúnaðarskyldur og upplýsingaöryggi</h4>
                    <p>Aðilar skulu meðhöndla allar upplýsingar sem gagnaðili veitir honum í tengslum við samning þennan sem trúnaðarupplýsingar sem samningsaðila er óheimilt að veita öðrum aðgang að nema til þess standi lagaskylda eða ákvörðun stjórnvalds eða dómstóls.</p>
                    <p>Til þess að Fruma geti veitt notanda þá þjónustu sem felst í notkun á kerfinu er Frumu nauðsynlegt að vinna með þær upplýsingar sem notandi setur inn í kerfið. Fruma kemur fram sem svokallaður vinnsluaðili í skilningi laga nr. 90/2018 um persónuvernd og vinnslu persónuupplýsinga í tengslum við slíka vinnslu.</p>
                    <p>Með samþykki skilmála þessara veitir notandi Frumu heimild til vinnslu og skráningar þeirra persónuupplýsinga er varða notkun og framkvæmd skilmálanna, eftir því sem við á.</p>
                    <p>Komi til breytinga á eignarhaldi Frumu hefur það ekki áhrif á réttindi og skyldur notanda og þjónusta Frumu mun haldast óbreytt óháð slíkum breytingum nema notanda verði tilkynnt um annað með hæfilegum fyrirvara.</p>
                    <h4>Aðgengi að persónuupplýsingum</h4>
                    <p>Persónuupplýsingar innan Frumu eru eingöngu aðgengilegar starfsfólki innan heilbrigðisgeirans og starfsfólki Frumu. Einnig kunna þær að vera aðgengilegar starfsfólki einhverra embætta, stofnunar eða sveitarfélaga. Upp getur komið sú staða að Frumu er skylt að afhenda stjórnvöldum eða dómstólum upplýsingar úr Frumu.</p>
                    <h4>Óviðráðanlegar aðstæður (Force Majeure)</h4>
                    <p>Hvorugur aðili skal teljast brotlegur gegn skilmálum þessum eða ábyrgur gagnvart hinum ef orsök er að rekja til atvika sem viðkomandi aðili hefur ekki á valdi sínu (óviðráðanleg atvik). Óviðráðanleg atvik í þessum skilningi eru m.a. en ekki einskorðuð við náttúruhamfarir, styrjaldir eða almennar óeirðir, aðgerðir opinberra aðila sem gera efndir/notkun ómögulegar, vinnudeilur o.þ.h.</p>
                    <h4>Önnur atriði</h4>
                    <p>Notandi ber ábyrgð á því að þjónusta hans sé innt af hendi af heilindum, fagmennsku og í samræmi við lög og almennt viðurkennd viðmið og góðar venjur.</p>
                    <p>Notandi er ábyrgðaraðili að vinnslu persónuupplýsinga viðskiptavina sinna. Í því felst að notandi ber ábyrgð á meðferð og öryggi upplýsinganna. Notandi ber n.t.t. ábyrgð á því að vinna hans á grundvelli samningsins uppfylli öll ákvæði laga um persónuvernd og vinnslu persónuupplýsinga eins og þau eru á hverjum tíma, nú lög nr. 90/2018.</p>
                    <p>Fruma tekur enga ábyrgð á beinu eða óbeinu tjóni notanda í tengslum við framkvæmd samnings þessa.</p>
                    <p>Undirritaður ábyrgist og staðfestir að hafa heimild í samræmi við lög, reglur og samþykktir félagsins til að skuldbinda viðkomandi félag.</p>
                </main>
            </div>
        )
    }
}

export default IgExpert;