import React, { useState } from 'react';
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { getToken } from '../../Utils/Common';
const token = getToken();

const IgExpert = () => {
    const [checked, setChecked] = useState(false);
    const [q1, setQ1] = useState("0");
    const [q2, setQ2] = useState("0");
    const [q3, setQ3] = useState("0");
    const [q4, setQ4] = useState("0");
    const [q5, setQ5] = useState("0");
    const [q6, setQ6] = useState("0");
    const [q7, setQ7] = useState("0");
    const [q8, setQ8] = useState("0");
    const [q9, setQ9] = useState("0");
    const [q10, setQ10] = useState("0");
    const [q11, setQ11] = useState("0");
    const [q12, setQ12] = useState("0");
    const [verkirtotal, setVerkirTotal] = useState(0);
    const [whatsyklalyf, setWhatsyklalyf] = useState("");
    const [aukaverkanir, setAukaverkanir] = useState("");
    
    const handleCheck = (e) => {
        console.log(checked);
        if (checked === false) {
            setChecked(true);
        } else {
            setChecked(false);
            setWhatsyklalyf("")
        }
    };

    const handleChange = event => {
        setQ1(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(event.target.value) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q6) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange2 = event => {
        setQ2(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(event.target.value) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q6) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange3 = event => {
        setQ3(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(event.target.value) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q6) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange4 = event => {
        setQ4(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(event.target.value) + parseInt(q5) + parseInt(q6) + parseInt(q6) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange5 = event => {
        setQ5(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(event.target.value) + parseInt(q6) + parseInt(q6) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange6 = event => {
        setQ6(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(event.target.value) + parseInt(q6) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange7 = event => {
        setQ7(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(event.target.value) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange8 = event => {
        setQ8(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q7) + parseInt(event.target.value) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange9 = event => {
        setQ9(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q7) + parseInt(q8) + parseInt(event.target.value) + parseInt(q10) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange10 = event => {
        setQ10(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q7) + parseInt(q8) + parseInt(q9) + parseInt(event.target.value) + parseInt(q11) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange11 = event => {
        setQ11(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q7) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(event.target.value) + parseInt(q12);
        setVerkirTotal(heildarskor);
    };
    const handleChange12 = event => {
        setQ12(event.target.value);
        let heildarskor = 0;
        heildarskor = parseInt(q1) + parseInt(q2) + parseInt(q3) + parseInt(q4) + parseInt(q5) + parseInt(q6) + parseInt(q7) + parseInt(q8) + parseInt(q9) + parseInt(q10) + parseInt(q11) + parseInt(event.target.value);
        setVerkirTotal(heildarskor);
    };
    const handleChangeSyking = event => {
        setWhatsyklalyf(event.target.value);
    };
    const handleChangeAuka = event => {
        setAukaverkanir(event.target.value);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const jsonbody = JSON.stringify({
            score: verkirtotal,
            Q1: q1,
            Q2: q2,
            Q3: q3,
            Q4: q4,
            Q5: q5,
            Q6: q6,
            Q7: q7,
            Q8: q8,
            Q9: q9,
            Q10: q10,
            Q11: q11,
            Q12: q12,
            whatsyklalyf: whatsyklalyf,
            aukaverkanir: aukaverkanir,
        });
        const customConfig = {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }, crossdomain: true
        };

        axios.post(global.config.api.url.live + '/igexpert', jsonbody, customConfig )
        .then(res => {
                if (res.data.statusCode === 201) {
                    window.location = "/notandi/IgExpert/";
                } else {
                    console.log("Villa: " + res.data.statusCode)
                }
            }
        )
        .catch(error => {
			const response = error.response;
            console.log("Villa: " + response);
        })
    };


    return (
        <div>
            <Topbar title="IgXpert virknimat - Skrá mat" heiti=""/>
            <main>
                <form onSubmit={handleSubmit}>
                <div className='greiningarprof'>
                    <h1>Mat á mótefnameðferð einstaklinga með ónæmisgalla</h1>
                    <p className='lysing'>Spurningalisti varðandi sýkingar. Eftirfarandi spurningar eiga við um fjölda sýkinga sem hafa þarfnast meðferðar síðustu 6 mánuði</p>
                    <br/><br/>
                    <div className='issyklalyfquestion'>
                        <h3 className="questions">Fyrirbyggjandi sýklalyf?</h3>
                        <div className='syklalyfquestion'>
                            <label className="for-checkbox-tools" htmlFor='showcheck'>Já</label><input type="checkbox" className='showcheck' name="showcheck" id="showcheck" checked={checked} onClick={handleCheck} />
                        </div>
                        <div className={'issyklalyf is' + checked}>
                            <input type="text" placeholder="Hvaða?" name="whatsyklalyf" id="whatsyklalyf" value={whatsyklalyf} onChange={handleChangeSyking} />
                        </div>
                    </div>
                    <h3 className="questions">Fjöldi eyrna-, ennis - eða kinnholusýkinga</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q1" checked={q1 === "0"} id="q1_0" value="0" onChange={handleChange} />
                        <label className="for-checkbox-tools" htmlFor="q1_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q1" checked={q1 === "1"} id="q1_1" value="1" onChange={handleChange} />
                        <label className="for-checkbox-tools" htmlFor="q1_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q1" checked={q1 === "2"} id="q1_2" value="2" onChange={handleChange} />
                        <label className="for-checkbox-tools" htmlFor="q1_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Fjöldi barka-/berkjubólgna meðhöndlaðar með sýklalyfjum</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q2" checked={q2 === "0"} id="q2_0" value="0" onChange={handleChange2} />
                        <label className="for-checkbox-tools" htmlFor="q2_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q2" checked={q2 === "1"} id="q2_1" value="1" onChange={handleChange2} />
                        <label className="for-checkbox-tools" htmlFor="q2_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>2<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q2" checked={q2 === "2"} id="q2_2" value="2" onChange={handleChange2} />
                        <label className="for-checkbox-tools" htmlFor="q2_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>3<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Fjöldi lungnabólgna</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q3" checked={q3 === "0"} id="q3_0" value="0" onChange={handleChange3} />
                        <label className="for-checkbox-tools" htmlFor="q3_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q3" checked={q3 === "2"} id="q3_2" value="2" onChange={handleChange3} />
                        <label className="for-checkbox-tools" htmlFor="q3_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q3" checked={q3 === "3"} id="q3_3" value="3" onChange={handleChange3} />
                        <label className="for-checkbox-tools" htmlFor="q3_3">
                            <i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div>
                        </label>
                    </div>
                    
                    <h3 className="questions">Fjöldi húðsýkinga</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q4" checked={q4 === "0"} id="q4_0" value="0" onChange={handleChange4} />
                        <label className="for-checkbox-tools" htmlFor="q4_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q4" checked={q4 === "1"} id="q4_1" value="1" onChange={handleChange4} />
                        <label className="for-checkbox-tools" htmlFor="q4_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q4" checked={q4 === "2"} id="q4_2" value="2" onChange={handleChange4} />
                        <label className="for-checkbox-tools" htmlFor="q4_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Fjöldi herpessýkinga <small>(t.d. frunsur)</small></h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q5" checked={q5 === "0"} id="q5_0" value="0" onChange={handleChange5} />
                        <label className="for-checkbox-tools" htmlFor="q5_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0 til 1 <br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q5" checked={q5 === "1"} id="q5_1" value="1" onChange={handleChange5} />
                        <label className="for-checkbox-tools" htmlFor="q5_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q5" checked={q5 === "2"} id="q5_2" value="2" onChange={handleChange5} />
                        <label className="for-checkbox-tools" htmlFor="q5_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div>
                        </label>
                    </div>
                    
                    <h3 className="questions">Fjöldi ígerða (abcessa) sem þurfti að hleypa út</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q6" checked={q6 === "0"} id="q6_0" value="0" onChange={handleChange6} />
                        <label className="for-checkbox-tools" htmlFor="q6_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q6" checked={q6 === "1"} id="q6_1" value="1" onChange={handleChange6} />
                        <label className="for-checkbox-tools" htmlFor="q6_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q6" checked={q6 === "2"} id="q6_2" value="2" onChange={handleChange6} />
                        <label className="for-checkbox-tools" htmlFor="q6_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div>
                        </label>
                    </div>
                    
                    <h3 className="questions">Fjöldi sveppasýkinga <small>(t.d. í húð eða kynfærum)</small></h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q7" checked={q7 === "0"} id="q7_0" value="0" onChange={handleChange7} />
                        <label className="for-checkbox-tools" htmlFor="q7_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q7" checked={q7 === "1"} id="q7_1" value="1" onChange={handleChange7} />
                        <label className="for-checkbox-tools" htmlFor="q7_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>1 til 2<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q7" checked={q7 === "2"} id="q7_2" value="2" onChange={handleChange7} />
                        <label className="for-checkbox-tools" htmlFor="q7_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>3<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Fjöldi tannholdssýkinga</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q8" checked={q8 === "0"} id="q8_0" value="0" onChange={handleChange8} />
                        <label className="for-checkbox-tools" htmlFor="q8_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q8" checked={q8 === "1"} id="q8_1" value="1" onChange={handleChange8} />
                        <label className="for-checkbox-tools" htmlFor="q8_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q8" checked={q8 === "2"} id="q8_2" value="2" onChange={handleChange8} />
                        <label className="for-checkbox-tools" htmlFor="q8_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Hve oft niðurgangur og/eða blóð í hægðum</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q9" checked={q9 === "0"} id="q9_0" value="0" onChange={handleChange9} />
                        <label className="for-checkbox-tools" htmlFor="q9_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q9" checked={q9 === "1"} id="q9_1" value="1" onChange={handleChange9} />
                        <label className="for-checkbox-tools" htmlFor="q9_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q9" checked={q9 === "2"} id="q9_2" value="2" onChange={handleChange9} />
                        <label className="for-checkbox-tools" htmlFor="q9_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div>
                        </label>
                    </div>
                    
                    <h3 className="questions">Fjöldi sýkinga í heila, liðum eða beini</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q10" checked={q10 === "0"} id="q10_0" value="0" onChange={handleChange10} />
                        <label className="for-checkbox-tools" htmlFor="q10_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q10" checked={q10 === "3"} id="q10_3" value="3" onChange={handleChange10} />
                        <label className="for-checkbox-tools" htmlFor="q10_3">
                            <i className="fa-regular fa-face-confounded"></i> <div>1<br/> sinni eða oftar</div>
                        </label>
                    </div>
                    
                    <h3 className="questions">Hve oft sýklalyf í æð eða innlögn á spítala</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q11" checked={q11 === "0"} id="q11_0" value="0" onChange={handleChange11} />
                        <label className="for-checkbox-tools" htmlFor="q11_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q11" checked={q11 === "2"} id="q11_2" value="2" onChange={handleChange11} />
                        <label className="for-checkbox-tools" htmlFor="q11_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q11" checked={q11 === "3"} id="q11_3" value="3" onChange={handleChange11} />
                        <label className="for-checkbox-tools" htmlFor="q11_3">
                            <i className="fa-regular fa-face-confounded"></i> <div>2<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Fjöldi sýklalyfjakúra</h3>
                    <div className="spurningar_checkboxes">
                        <input className="checkbox-tools" type="radio" name="q12" checked={q12 === "0"} id="q12_0" value="0" onChange={handleChange12} />
                        <label className="for-checkbox-tools" htmlFor="q12_0">
                            <i className="fa-regular fa-face-smile"></i> <div>0 til 1<br/> sinni</div>
                        </label><input className="checkbox-tools" type="radio" name="q12" checked={q12 === "1"} id="q12_1" value="1" onChange={handleChange12} />
                        <label className="for-checkbox-tools" htmlFor="q12_1">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>2 til 3<br/> sinnum</div>
                        </label><input className="checkbox-tools" type="radio" name="q12" checked={q12 === "2"} id="q12_2" value="2" onChange={handleChange12} />
                        <label className="for-checkbox-tools" htmlFor="q12_2">
                            <i className="fa-regular fa-face-confounded"></i> <div>4<br/> sinnum eða oftar</div>
                        </label>
                    </div>

                    <h3 className="questions">Önnur einkenni<br/><small>(t.d. aukaverkanir af meðferð)</small></h3>
                    <textarea className='textareaprof' onChange={handleChangeAuka}>{aukaverkanir}</textarea>
                    <div class="nyttprof"><input type="submit" class="vistaprof buatilnotenda" value="Skrá mat" /><br/><br/></div>
                </div>
                </form>
            </main>
        </div>
    )
}


export default IgExpert;