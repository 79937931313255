import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import Topbar from '../templates/Topbar';

import { withTranslation} from 'react-i18next';

export class EducationalMaterial extends Component {
    render() {
        const { t } = this.props;
        return (
            <div>
                <Topbar title="Fræðsluefni" heiti=""/>
                <main>
                    <p>Hér getur þú fundið fræðsluefni um sjúkdóma, forvarnir og fyrirbyggjandi leiðir til heilbrigðara og betra lífs. Hér eru líka leiðbeiningar um hvernig á að nota Frumu.</p>
                    <div className='education'>
                        <h2>Ónæmisgallar - Hvað er hægt að gera til að draga úr sýkingum?</h2>
                        <p>Meðfæddir og áunnir ónæmisgallar eru orð sem notuð eru yfir marga sjúkdóma sem koma fram í ónæmiskerfinu.</p>
                        <p><Link to="/notandi/fraedsluefni/onaemisgallar-hvad-er-haegt-ad-gera-til-að-draga-ur-sykingum" className='lesameira'>{t("lesameira")}</Link></p>
                    </div>
                    <div className='education'>
                        <h2>Hvað eru mótefni?</h2>
                        <p>Mótefni eru prótín sem eru mikilvægur hluti ónæmiskerfis líkamans. Helsta hlutverk mótefna er að vernda okkur gegn innrás sýkla og móta ónæmissvar líkamans. Þau eru framleidd af svokölluðum B eitilfrumum, að stærstum hluta í beinmerg, eitlum og meltingarvegi. </p>
                        <p><Link to="/notandi/fraedsluefni/hvad-eru-motefni" className='lesameira'>{t("lesameira")}</Link></p>
                    </div>
                    <div className='education'>
                        <h2>Hvað get ég gert til að styrkja ónæmiskerfið?</h2>
                        <p>Ónæmiskerfið er lykill að vörnum okkar gegn sýklum og öðrum sjúkdómsvöldum. Frumur og líffæri ónæmis­ kerfisins eru stöðugt að taka til í líkamanum, losa hann við frumur sem geta myndað krabbamein, dauðan vef og slökkva á bólgu, jafnframt því að viðhalda heilbrigði og hæfni ónæmiskerfisins.</p>
                        <p><Link to="/notandi/fraedsluefni/hvad-get-eg-gert-til-ad-styrkja-onaemiskerfid" className='lesameira'>{t("lesameira")}</Link></p>
                    </div>
                    <div className='education'>
                        <h2>Dagbók fyrir mótefnagjafir undir húð</h2>
                        <p>Þessi dagbók er fyrir þig sem ert í mótefnameðferð í heimahúsi. Hér skráir þú sjálf/ur mótefnagjafir, hvernig þér líður eftir gjöfina og hvernig líðan þín er á milli gjafa. Tilgangur skráningar er gefa betri sýn á eigið heilsufar. Þetta gagnast bæði þér og fagfólki til að meta árangur meðferðar.</p>
                        <p><Link to="/notandi/fraedsluefni/dagbok-fyrir-motefni" className='lesameira'>{t("lesameira")}</Link></p>
                    </div>
                </main>
            </div>
        )
    }
}

export default withTranslation()(EducationalMaterial);