import React, { Component } from 'react'
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken, removeUserSession, setRefreshUserSession, getSession, getRefreshToken,getResearch } from '../../Utils/Common';
const token = getToken();
const research = getResearch();
const refreshtoken = getRefreshToken();
const session = getSession();

export class IgExpert extends Component {
    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {

        axios.get(global.config.api.url.live + '/igexpert/all/', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.ige, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch(global.config.api.url.live + '/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        const { t } = this.props;
        //console.log(posts);
        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <Topbar title={t('menu.igexpert')} heiti=""/>
                        <main>
                            <h2>Einstaklingsmiðuð samráðsmeðferð mótefnagjafa með stuðningi IgXpert á Landspítala.</h2>
                            {research === "1" &&
                                <p className='rannsoknminafstada'>Ég samþykki að taka þátt í þessari rannsókn</p>
                            }
                            {research === "0" &&
                                <p className='rannsoknminafstada'>Viltu samþykkja þátttöku í rannsókn <a href="/notandi/IgExpert/samþykki">Skoða nánar hér og taka afstöðu</a></p>
                            }
                            {research === "2" &&
                                <p className='rannsoknminafstada'>Ég samþykki <strong>ekki</strong> að taka þátt í þessari rannsókn</p>
                            }
                            <h1>Mat á mótefnameðferð - Spurningalisti varðandi sýkingar<Link to="/notandi/IgExpert/skra" className="addUrgency"><i className="fa-solid fa-square-plus"></i> Skrá mat</Link></h1>
                            <table className="qualityoflife">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Skráð</th>
                                        <th className="tl hideonmobile">Fyrirbyggjandi sýklalyf?</th>
                                        <th className="tl hideonmobile">Einkenni</th>
                                        <th className="tl"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {posts.map(post =>
                                    <tr key={post.id}>
                                        <td width="1%"><div className='heildarskor_mynd'><i className={getHeildarskor(post.score)}></i></div></td>
                                        <td >{moment(post.createdon).format('Do MMM YYYY')}</td>
                                        <td className="tl hideonmobile">{post.syklalif}</td>
                                        <td className="tl hideonmobile">{post.einkenni}</td>
                                        <td className="tr"><a href={"/notandi/IgExpert/w/" + post.id} className='skodamat'>Skoða nánar</a></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div className="urgencies">
                        <h3>{t('menu.lifsgaedi')} <Link to="/notandi/hae/lifsgaedi/"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <p>Ekkert próf hefur verið tekið</p>
                        <div className="nyttprof">
                            <a href='/notandi/hae/lifsgaedi/'>Taka próf</a>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function getHeildarskor(heildarskor) {
    let heildarskorIcon = "";
    if (heildarskor >= 3) {
        heildarskorIcon = "fa-regular fa-face-confounded";
    } else if (heildarskor === 2) {
        heildarskorIcon = "fa-regular fa-face-frown-slight";
    } else if (heildarskor === 1) {
        heildarskorIcon = "fa-regular fa-face-smile";
    }
    return heildarskorIcon;
}

export default withTranslation()(IgExpert);